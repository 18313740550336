import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import Seo from '../components/Seo'
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import { sButton, sSection } from '../style'
import { useLangContext } from '../context/lang.context'

const seo = {
  title: {
    en: '404 not found',
  },
  desc: {
    en: 'Seems like the page you are looking for does not exist. Go back to homepage by clicking the button',
  },
}

const content = {
  label: {
    en: 'ERROR 404',
  },
  title: {
    en: 'Page not found!',
  },
  desc: {
    en: 'Seems like the page you are looking for does not exist. Go back to homepage by clicking the button',
  },
  button: {
    en: 'Return',
  },
}

// markup
const NotFoundPage = () => {
  const { lang } = useLangContext()

  return (
    <>
      <Seo title={seo.title[lang]} desc={seo.desc[lang]} url='/404/' />
      <Menu />
      <main css={sSection}>
        <span className='label'>{content.label[lang]}</span>
        <h1>{content.title[lang]}</h1>
        <p>{content.desc[lang]}</p>
        <Link to='/' css={sButton}>
          {content.button[lang]}
        </Link>
      </main>
      <Footer />
    </>
  )
}

export default NotFoundPage
